import React, { useContext, useState, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from '../../Auth/AuthContext';
import { gsap } from 'gsap';
import styles from './Header.module.css';
import logo from '../../Assets/logo.png';
import profileIcon from '../../Assets/profile-icon.png';
import burgerIcon from '../../Assets/burger.svg';
import closeIcon from '../../Assets/smallClose.svg';
import { Link as ScrollLink } from 'react-scroll';

export default function Header() {
    const { user, logout } = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const mobileMenuRef = useRef(null);
    const menuContentRef = useRef(null);
    const closeIconRef = useRef(null);
    const authActionsRef = useRef(null);
    const location = useLocation();

    const toggleMenu = () => {
        if (menuOpen) {
            gsap.timeline()
                .to([menuContentRef.current, closeIconRef.current, authActionsRef.current], {
                    opacity: 0,
                    scale: 0.9,
                    duration: 0.5,
                    stagger: -0.1,
                })
                .to(mobileMenuRef.current, {
                    x: "100vw",
                    duration: 0.2,
                    ease: "none",
                });
        } else {
            gsap.timeline()
                .to(mobileMenuRef.current, {
                    x: 0,
                    duration: 0.4,
                    ease: "none",
                })
                .fromTo(
                    [menuContentRef.current, closeIconRef.current, authActionsRef.current],
                    { opacity: 0, scale: 0.9 },
                    { opacity: 1, scale: 1, duration: 0.3, stagger: 0.1 },
                    "+=0.1"
                );
        }

        setMenuOpen(!menuOpen);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const isLandingPage = location.pathname === "/";

    return (
        <header className={styles.header}>
            <div className={styles.logo__area}>
                <img src={logo} alt='Carica Web logo' className={styles.logo} />
                <p>CARICA <span className='main__blue'>WEB</span></p>
            </div>

            <nav className={styles.navDesktop}>
                <ul>
                    <li><Link className='basic__link' to='/' onClick={scrollToTop}>HOME</Link></li>
                    <li>
                        {isLandingPage ? (
                            <ScrollLink to="services" smooth={true} duration={500} className='basic__link'>
                                SERVICES
                            </ScrollLink>
                        ) : (
                            <Link className='basic__link' to="/#services">
                                SERVICES
                            </Link>
                        )}
                    </li>
                    <li><Link className='basic__link' to='/plans' onClick={scrollToTop}>PRICING</Link></li>
                    <li><Link className='basic__link' to='/drive' onClick={scrollToTop}>CARICA DRIVE</Link></li>
                    <li><Link className='basic__link' to='/file-sender' onClick={scrollToTop}>FILE SENDER</Link></li>
                    <li>
                        {isLandingPage ? (
                            <ScrollLink to="contact" smooth={true} offset={-200} duration={500} className='basic__link'>
                                CONTACT US
                            </ScrollLink>
                        ) : (
                            <Link className='basic__link' to="/#contact">
                                CONTACT US
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>

            <div className={`${styles.profile__wrapper} ${styles.desktopOnly}`}>
                {user ? (
                    <>
                        <Link className='icon__link' to='/account'>
                            <img src={profileIcon} alt='Profile Button' />
                        </Link>
                        <button className='basic__link' onClick={logout}>LOGOUT</button>
                    </>
                ) : (
                    <div className={styles.authActions}>
                        <Link className='basic__link' to='/login'>LOGIN</Link>
                        <Link className='basic__link' to='/register'>REGISTER</Link>
                    </div>
                )}
            </div>

            <div className={styles.burgerIcon} onClick={toggleMenu}>
                <img src={burgerIcon} alt="Menu" />
            </div>

            <div
                className={`${styles.mobileMenu}`}
                ref={mobileMenuRef}
                style={{ transform: "translateX(100vw)" }}
            >
                <div className={styles.closeIcon} onClick={toggleMenu} ref={closeIconRef}>
                    <img src={closeIcon} alt="Close Menu" />
                </div>
                <ul ref={menuContentRef}>
                    <li><Link className='basic__link' to='/' onClick={() => {
                        toggleMenu();
                        scrollToTop();
                    }}>HOME</Link></li>
                    <li>
                        {isLandingPage ? (
                            <ScrollLink to="services" smooth={true} duration={500} className="basic__link" onClick={toggleMenu}>
                                SERVICES
                            </ScrollLink>
                        ) : (
                            <Link className="basic__link" to="/#services" onClick={toggleMenu}>
                                SERVICES
                            </Link>
                        )}
                    </li>
                    <li><Link className='basic__link' to='/plans' onClick={() => { toggleMenu(); scrollToTop(); }}>PRICING</Link></li>
                    <li><Link className='basic__link' to='/drive' onClick={() => { toggleMenu(); scrollToTop(); }}>CARICA DRIVE</Link></li>
                    <li><Link className='basic__link' to='/file-sender' onClick={() => { toggleMenu(); scrollToTop(); }}>FILE SENDER</Link></li>
                    <li>
                        {isLandingPage ? (
                            <ScrollLink to="contact" smooth={true} duration={500} offset={-120} className="basic__link" onClick={toggleMenu}>
                                CONTACT US
                            </ScrollLink>
                        ) : (
                            <Link className="basic__link" to="/#contact" onClick={toggleMenu}>
                                CONTACT US
                            </Link>
                        )}
                    </li>
                </ul>
                <div className={`${styles.mobileAuthActions} ${styles.mobileOnly}`} ref={authActionsRef}>
                    {user ? (
                        <>
                            <Link className='basic__link' to='/account'>MY ACCOUNT</Link>
                            <button className='basic__link' onClick={() => { logout(); toggleMenu(); }}>LOGOUT</button>
                        </>
                    ) : (
                        <>
                            <Link className='basic__link' to='/login' onClick={() => { toggleMenu(); scrollToTop(); }}>LOGIN</Link>
                            <Link className='basic__link' to='/register' onClick={() => { toggleMenu(); scrollToTop(); }}>REGISTER</Link>
                        </>
                    )}
                </div>
            </div>
        </header>
    );
}