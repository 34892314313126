import httpClient from './httpClient';

const authService = {
    login: (credentials) => httpClient.post('/auth/login', credentials),
    register: (userData) => httpClient.post('/auth/register', userData),
    logout: () => httpClient.get('/auth/logout'),
    getUser: () => httpClient.get('/account/info'),
    verifyToken: () => httpClient.get('/auth/verify'),
    changeUsername: (newUsername) => httpClient.put('/account/change-username', { newUsername }),
    changeEmail: (newEmail) => httpClient.put('/account/change-email', { newEmail }),
    changePassword: (password) => httpClient.put('/account/change-password', { password }),
    changeVAT: (VAT) => httpClient.put('/account/change-vat', { VAT }),
};

export default authService;
