import {useEffect, useRef, useState} from 'react';
import styles from './Landing.module.css';
import Lottie from 'react-lottie';
import landingAnimationJson from '../../Animations/landing.json';
import webDevAnimationJson from '../../Animations/Coding.json';
import graphicDesignAnimationJson from '../../Animations/design.json';
import marketingAnimationJson from '../../Animations/marketing.json';
import fileSenderAnimationJson from '../../Animations/file_sender.json';
import cloudAnimationJson from '../../Animations/cloud.json';
import apiAnimationJson from '../../Animations/Api.json';
import invoiceAnimationJson from '../../Animations/invoice.json';
import { FaUser, FaEnvelope, FaCommentDots } from 'react-icons/fa';
import {Link, useLocation} from 'react-router-dom';
import mailingService from "../../API/mailingServices";
import MainBtn from "../../Shared/MainBtn/MainBtn";
import {Helmet} from "react-helmet";

export default function Landing() {
    const fileSenderRef = useRef(null);
    const landingRef = useRef(null);
    const webDevRef = useRef(null);
    const graphicDesignRef = useRef(null);
    const marketingRef = useRef(null);


    const servicesRef = useRef(null);
    const contactRef = useRef(null);
    const location = useLocation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    useEffect(() => {


        const hash = location.hash;

        if (hash === '#services' && servicesRef.current) {
            servicesRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === '#contact' && contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(!formData.name || !formData.email || !formData.message) {
                alert('Please fill out all fields.');
                return;
            }

            // Validate email with regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                alert('Please enter a valid email address.');
                return;
            }

            await mailingService.sendBasicSupportEmail(formData);
            alert('Message sent successfully!');
            setFormData({
                name: '',
                email: '',
                message: ''
            });
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send message. Please try again later.');
        }
    };
    const landingAnimation = {
        loop: true,
        autoplay: true,
        animationData: landingAnimationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const webDevAnimation = {
        loop: true,
        autoplay: true,
        animationData: webDevAnimationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const graphicDesignAnimation = {
        loop: true,
        autoplay: true,
        animationData: graphicDesignAnimationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const marketingAnimation = {
        loop: true,
        autoplay: true,
        animationData: marketingAnimationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const fileSenderAnimation = {
        loop: true,
        autoplay: true,
        animationData: fileSenderAnimationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const cloudAnimation = {
        loop: true,
        autoplay: true,
        animationData: cloudAnimationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const apiAnimation = {
        loop: true,
        autoplay: true,
        animationData: apiAnimationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const invoiceAnimation = {
        loop: true,
        autoplay: true,
        animationData: invoiceAnimationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <>
            <Helmet>
                <title>Carica Web</title>
            </Helmet>
            <section className={styles.landing__section}>
                <div className={styles.landing__section__info}>
                    <h1>
                        Your <span className='main__blue'>vision</span>, <br/>
                        our <span className='main__blue'>innovation.</span>
                    </h1>
                    <p>
                        At Carica Web, we bring expertise and innovation together to drive your business forward. Our
                        solutions are designed to empower growth, streamline your operations, and take your ideas to the
                        next level.
                    </p>
                    <div className={styles.landing__btns__wrapper}>
                        <Link to='/get-started' className='main__btn'>GO ONLINE</Link>
                        <Link to='/#services' className='secondary__btn'>EXPLORE SERVICES</Link>
                    </div>
                </div>
                <div className={styles.landing__lottie}>
                    <Lottie options={landingAnimation} ref={landingRef}></Lottie>
                </div>
            </section>
            <section ref={servicesRef} id='service' className={styles.services__section}>
                <h1>OUR <span className='main__blue'>SERVICES</span></h1>
                <div className={styles.services__grid}>
                    <div className={styles.service}>
                        <div className={styles.webDevLottieWrapper}>
                            <Lottie width='auto' height='auto' options={webDevAnimation} ref={webDevRef}></Lottie>
                        </div>
                        <h4><span className='main__blue'>WEB</span> DEVELOPMENT</h4>
                        <p>From simple websites to complex web applications, we build secure, scalable, and
                            user-friendly solutions that bring your ideas to life. Whether you’re launching a new
                            project or optimizing your current platform, we’ve got you covered.</p>
                    </div>
                    <div className={styles.service}>
                        <div className={styles.designLottieWrapper}>
                            <Lottie width='auto' height='auto' options={graphicDesignAnimation}
                                    ref={graphicDesignRef}></Lottie>
                        </div>
                        <h4>UI/UX & GRAPHIC <span className='main__blue'>DESIGN</span></h4>
                        <p>From simple websites to complex web applications, we build secure, scalable, and
                            user-friendly solutions that bring your ideas to life. Whether you’re launching a new
                            project or optimizing your current platform, we’ve got you covered.</p>
                    </div>
                    <div className={styles.service}>
                        <div className={styles.marketingLottieWrapper}>
                            <Lottie width='auto' height='auto' options={marketingAnimation} ref={marketingRef}></Lottie>
                        </div>
                        <h4>DIGITAL <span className='main__blue'>MARKETING</span></h4>
                        <p>From simple websites to complex web applications, we build secure, scalable, and
                            user-friendly solutions that bring your ideas to life. Whether you’re launching a new
                            project or optimizing your current platform, we’ve got you covered.</p>
                    </div>
                </div>
            </section>
            <section className={styles.carica__drive__section}>
                <h1>CARICA <span className='main__blue'>APPS</span></h1>
                <div className={styles.grid__container}>
                    <div className={styles.grid__item__small}>
                        <div className={styles.cloudLottieWrapper}>
                            <Lottie options={fileSenderAnimation} ref={fileSenderRef}></Lottie>
                        </div>
                        <h4>SEND <span className="main__blue">FILES</span> & CREATE TEMPORARY <span
                            className="main__blue">LINKS</span></h4>
                        <p>Easily share files of any size with secure, time-sensitive links. Whether it’s a quick
                            document or large project files, our file sender ensures your data is delivered efficiently
                            and safely—no hassle, no limits.</p>
                        <Link to='/file-sender' className={styles.goToLink}>Open Carica Drive</Link>
                    </div>
                    <div className={styles.grid__item__small}>
                        <div className={styles.cloudLottieWrapper}>
                            <Lottie options={cloudAnimation}></Lottie>
                        </div>
                        <h4><span className='main__blue'>UPLOAD</span> & STORE <span className='main__blue'>FILES</span>
                        </h4>
                        <p>Easily share files of any size with secure, time-sensitive links. Whether it’s a quick
                            document or large project files, our file sender ensures your data is delivered efficiently
                            and safely—no hassle, no limits.</p>
                        <Link to='/file-sender' className={styles.goToLink}>Open Carica Drive</Link>
                    </div>
                    <div className={styles.grid__item__small}>
                        <div className={styles.apiLottieWrapper}>
                            <Lottie options={apiAnimation}></Lottie>
                        </div>
                        <h4><span className='main__blue'>API</span> INTEGRATION FOR <span
                            className='main__blue'>DEVELOPERS</span></h4>
                        <p>Integrate our cloud services directly into your own platform with our easy-to-use API. From
                            file uploads to secure storage management, we provide the tools to connect your applications
                            with the cloud effortlessly.</p>
                        <Link to='/file-sender' className={styles.goToLink}>Open Docs</Link>
                    </div>
                    <div className={styles.grid__item__small}>
                        <div className={styles.invoiceLottieWrapper}>
                            <Lottie options={invoiceAnimation}></Lottie>
                        </div>
                        <h4><span className='main__blue'>INVOICING</span> & DOCUMENTS <span
                            className='main__blue'>MANAGER</span></h4>
                        <p>Easily generate, manage, and securely store your invoices and essential documents. Our
                            document manager lets you create professional invoices on demand, organize files
                            effortlessly, and share them with clients or team members for a streamlined workflow.</p>
                        <Link to='/invoice-manager' className={styles.goToLink}>Open Invoice Manager</Link>
                    </div>
                </div>
            </section>
            <section ref={contactRef} id='contact' className={styles.contact__section}>
                <h1>
                    WE'VE BEEN WAITING
                    <br/>
                    <span className="main__blue">FOR YOU!</span>
                </h1>
                <p>WE WANT TO HEAR FROM YOU! LET US KNOW HOW WE CAN HELP.</p>
                <div className={styles.form__container}>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.input__group}>
                            <FaUser className={styles.icon}/>
                            <input
                                type="text"
                                placeholder="Enter your name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.input__group}>
                            <FaEnvelope className={styles.icon}/>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.input__group}>
                            <FaCommentDots className={styles.textarea__icon}/>
                            <textarea
                                placeholder="Enter your message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className={styles.textarea}
                            ></textarea>
                        </div>
                        <MainBtn
                            text="SEND MESSAGE"
                            type="submit"
                            onClick={handleSubmit}
                        />
                    </form>

                </div>
            </section>
        </>
    );
}