import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import styles from './Plans.module.css';
import arrow from '../../Assets/arrow-right.svg';
import tick from '../../Assets/tick_blue.svg';
import Checkout from './Checkout/Checkout';
import { AuthContext } from "../../Auth/AuthContext";
import paymentService from '../../API/paymentServices';
import authService from '../../API/authServices';
import { loadStripe } from "@stripe/stripe-js";
import MainOptionsPrompt from "../../Shared/MainOptionsPrompt/MainOptionsPrompt";
import { Helmet } from "react-helmet";

export default function Plans() {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    const [currentPlan, setCurrentPlan] = useState('');
    const [showPrompt, setShowPrompt] = useState(false);
    const [isDowngrade, setIsDowngrade] = useState(false);

    const plans = [
        {
            name: 'STARTER',
            price: '€8',
            priceId: 'price_1QDme1Bas8Ty8TFhFd5x7Hvk',
            description: 'For individuals or small businesses just getting started.',
            features: [
                '100GB Carica Drive Storage',
                '500 Invoices / Month',
                '1000 API Calls / Month'
            ]
        },
        {
            name: 'ESSENTIALS',
            price: '€11',
            priceId: 'price_1QDmeSBas8Ty8TFhYHXEHcY6',
            description: 'Core tools for small businesses.',
            features: [
                '200GB Carica Drive Storage',
                '1500 Invoices / Month',
                'File Sender',
                '5000 API Calls / Month'
            ]
        },
        {
            name: 'PRO',
            price: '€32',
            priceId: 'price_1QDmf1Bas8Ty8TFh2bHssu4t',
            description: 'Designed for businesses starting to expand.',
            features: [
                '1TB Carica Drive Storage',
                '3K Invoices / Month',
                'File Sender',
                '20K API Calls / Month'
            ]
        },
        {
            name: 'BUSINESS',
            price: '€68',
            priceId: 'price_1QDmfbBas8Ty8TFhcSWmx36S',
            description: 'Comprehensive solutions for growing companies.',
            features: [
                '2TB Carica Drive Storage',
                '10K Invoices / Month',
                'File Sender',
                '50K API Calls / Month'
            ]
        },
        {
            name: 'ADVANCED',
            price: '€140',
            priceId: 'price_1QDmfsBas8Ty8TFhaelLyJqC',
            description: 'Advanced tools for larger operations.',
            features: [
                '5TB Carica Drive Storage',
                '25K Invoices / Month',
                'File Sender',
                '100K API Calls / Month'
            ]
        },
        {
            name: 'ENTERPRISE',
            price: '€290',
            priceId: 'price_1QDmgcBas8Ty8TFhTFKX1KR9',
            description: 'Full suite of features for large businesses and enterprises.',
            features: [
                '10TB Carica Drive Storage',
                '50K Invoices / Month',
                'File Sender',
                'Unlimited API Calls / Month'
            ]
        },
    ];

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await authService.getUser();
                const userPlan = response.data.plan;
                setCurrentPlan(userPlan || 'free');
                console.log("Current Plan:", userPlan);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };
        fetchUserInfo();
    }, []);

    const handlePlanSelection = (plan) => {
        if (!user) {
            navigate('/login');
        } else {
            const isCurrentPlan = currentPlan.trim().toLowerCase() === plan.name.trim().toLowerCase();
            if (currentPlan === 'free') {
                setSelectedPlan(plan);
                setIsCheckoutOpen(true);
                setClosing(false);
            } else if (!isCurrentPlan) {
                const isDowngrade = plans.findIndex(p => p.name === plan.name) < plans.findIndex(p => p.name === currentPlan);
                setSelectedPlan(plan);
                setIsDowngrade(isDowngrade);
                setShowPrompt(true);
            }
        }
    };

    const handleConfirmPlanChange = async () => {
        try {
            if (selectedPlan) {
                if (isDowngrade) {
                    await paymentService.downgradePlan({ productId: selectedPlan.priceId });
                    console.log('Plan downgraded successfully.');
                } else {
                    const response = await paymentService.upgradePlan({ productId: selectedPlan.priceId });
                    const { sessionId } = response.data;
                    const stripe = await stripePromise;
                    const { error } = await stripe.redirectToCheckout({ sessionId });
                    if (error) console.error('Stripe checkout error:', error);
                }
            } else {
                console.error('No plan selected.');
            }
        } catch (error) {
            console.error('Error confirming plan change:', error);
        } finally {
            setShowPrompt(false);
        }
    };

    return (
        <div className={styles.plans__page}>
            <Helmet>
                <title>Carica Web - Plans</title>
            </Helmet>
            <h2 className={styles.heading}>Built for visionaries, designed for success</h2>
            <div className={styles.plans}>
                {plans.map((plan, index) => (
                    <div key={index} className={styles.plan}>
                        <div className={styles.plan__info}>
                            <h4>{plan.name}</h4>
                            <p>{plan.description}</p>
                        </div>
                        <div className={styles.plan__price}>
                            <h3>{plan.price} <span className='plan__span'>/ month</span></h3>
                        </div>
                        <div className={styles.pros}>
                            {plan.features.map((feature, featureIndex) => (
                                <div key={featureIndex} className={styles.pro}>
                                    <img className={styles.tick} src={tick} alt="tick"/>
                                    <p>{feature}</p>
                                </div>
                            ))}
                        </div>
                        <div className={styles.buttonContainer}>
                            <button
                                className={`get__started__btn ${currentPlan === plan.name ? styles.isCurrentPlan : ''}`}
                                onClick={() => handlePlanSelection(plan)}
                            >
                                {currentPlan === plan.name
                                    ? 'Current Plan'
                                    : plans.findIndex(p => p.name === plan.name) < plans.findIndex(p => p.name === currentPlan)
                                        ? 'Downgrade Plan'
                                        : 'Upgrade Plan'}

                                {currentPlan !== plan.name && <img src={arrow} alt="arrow"/>}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Overlay for dimming and closing */}
            {isCheckoutOpen && (
                <div
                    className={styles.overlay}
                    onClick={() => setIsCheckoutOpen(false)}
                ></div>
            )}

            {/* Checkout component */}
            {isCheckoutOpen && (
                <Checkout
                    selectedPlan={selectedPlan}
                    closeCheckout={() => setIsCheckoutOpen(false)}
                    closing={closing}
                />
            )}

            {showPrompt && (
                <MainOptionsPrompt
                    title={isDowngrade ? "You are about to downgrade your plan." : "You are about to upgrade your plan."}
                    onCancel={() => setShowPrompt(false)}
                    onConfirm={handleConfirmPlanChange}
                    description={isDowngrade ? "You will instantly lose access to your current plan's features. You will be charged at the new plan's price on your renewal date." : "You will be charged instantly and your plan will be upgraded immediately. Unused period will be automatically deducted."}
                />
            )}
        </div>
    );
}