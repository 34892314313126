import styles from './AdminLayout.module.css';
import settingsIcon from '../../Assets/settings.svg'
import {Link} from "react-router-dom";
import Settings from "./Settings/Settings";

export default function AdminLayout({view}) {
    return (
        <div className={styles.admin__wrapper}>
            <div className={styles.admin__header}>
                <div className={styles.admin__navigator}>
                    <Link className={`admin__btn`} to='/admin/settings'><img src={settingsIcon} className={styles.settings__img} /></Link>
                </div>
            </div>
            <div className={styles.admin__content}>
                <h1 className={styles.heading}>{view}</h1>
                <div className={styles.admin__content__inner}>
                    {view === 'Settings' ? <Settings /> : null}
                </div>
            </div>
        </div>
    )
}