import httpClient from "./httpClient";

const paymentService = {
    createSession: (productIds, customerEmail, userId, VAT, countryCode) => {
        return httpClient.post('/payment/create-session', {
            productIds,
            customerEmail,
            userId,
            VAT,
            countryCode
        });
    },
    cancelSubscription: () => {
        return httpClient.post('/payment/cancel-plan');
    },
    upgradePlan: (data) => {
        return httpClient.post('/payment/upgrade-plan', data);
    },
    downgradePlan: (data) => {
        return httpClient.post('/payment/downgrade-plan', data);
    },
    getPaymentAccount: () => {
        return httpClient.get('/payment/get-account');
    },
    renewPlan: () => {
        return httpClient.post('/payment/renew-plan');
    },
    createPaymentMethodSession: (customerEmail, userId) => {
        return httpClient.post('/payment/create-payment-method-session', {
            customerEmail,
            userId,
        });
    },
    deleteCard: (data) => {
        return httpClient.post('/payment/delete-card', data);
    },
    setDefaultCard: (data) => {
        return httpClient.post('/payment/setDefaultPaymentCard', data)
    },
    getPriceAfterTax: (data) => {
        return httpClient.post('/payment/price-after-tax', data);
    },
    validateVAT: (VAT) => {
        return httpClient.post('/payment/validate-VAT', {
            VAT,
        });
    }
};

export default paymentService;