import React, { useState, useRef } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styles from './Register.module.css';
import Lottie from 'react-lottie';
import landingAnimationJson from '../../../Animations/register.json';
import authServices from '../../../API/authServices';

export default function Register() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const lottieRef = useRef(null);
    const navigate = useNavigate();

    const landingAnimation = {
        loop: true,
        autoplay: true,
        animationData: landingAnimationJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const response = await authServices.register({
                email,
                username,
                password,
            });

            if (response.data.error) {
                setError(response.data.error);
            } else {
                setError('');
                // Assuming the backend handles setting the HTTP-only token, we can navigate to /drive
                navigate('/login');
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                setError(err.response.data.error);
            } else {
                setError('Registration failed. Please try again.');
            }
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.infoSection}>
                <div className={styles.bullet}></div>
                <h2>Let us help you run your <br /><span className='main__blue'>Business online</span></h2>
                <div className={styles.lottieWrapper}>
                    <Lottie options={landingAnimation} ref={lottieRef}></Lottie>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formSmallHeading}>
                    <h4>Get started</h4>
                    <p>Create your new account now</p>
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="example@example.com"
                        className={`${styles.input} ${error ? styles.errorInput : ''}`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        placeholder="user5736"
                        className={`${styles.input} ${error ? styles.errorInput : ''}`}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        placeholder="Enter your password"
                        className={`${styles.input} ${error ? styles.errorInput : ''}`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="confirmPassword">Repeat Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        placeholder="Confirm your password"
                        className={`${styles.input} ${error ? styles.errorInput : ''}`}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>

                <p className={`${styles.errorMessage} ${error ? styles.showError : ''}`}>
                    {error}
                </p>

                <div className={styles.action_wrapper}>
                    <button type="submit" className='main__btn'>Register</button>
                    <p className={styles.loginPrompt}>Already have an account? <Link className={styles.loginLink} to='/login'>Login</Link></p>
                </div>
            </form>
        </div>
    );
}