import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ requiredRole }) => {
    const { user, loading, role } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>;
    }

    console.log(`ProtectedRoute Check - User: ${user}, Role: ${role}, Required Role: ${requiredRole}`);

    // Redirect to login if not authenticated
    if (!user) {
        console.log("User not authenticated. Redirecting to login.");
        return <Navigate to="/login" />;
    }

    // Role-based access control
    if (requiredRole && role !== requiredRole) {
        console.log(`Access denied. Required role: ${requiredRole}, User role: ${role}`);
        return <Navigate to="/access-denied" />; // Redirect to an access denied page
    }

    console.log("Access granted!");
    return <Outlet />;
};

export default ProtectedRoute;