import React, { useEffect, useState } from 'react';
import styles from './MyFiles.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import driveService from '../../../API/driveServices';

const MyFiles = ({ selectedItems, setSelectedItems, refresh, setRefresh }) => {
    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [lastSelectedItem, setLastSelectedItem] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    const getCurrentPath = () => {
        const params = new URLSearchParams(location.search);
        return params.get('path') || '';
    };

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
    };

    const getBaseName = (key, folderPath) => {
        return key.replace(`${folderPath}/`, '');
    };

    const fetchFilesAndFolders = (folderPath) => {
        driveService.getFiles(folderPath)
            .then(response => {
                const objects = response.data.objects || [];
                const fetchedFolders = objects.filter(item => item.Type === 'folder');
                const fetchedFiles = objects.filter(item => item.Type === 'file');

                setFolders(fetchedFolders);
                setFiles(fetchedFiles);
                setRefresh(false);
            })
            .catch(error => {
                console.error('Error fetching files and folders:', error);
            });
    };

    useEffect(() => {
        const currentPath = getCurrentPath();
        fetchFilesAndFolders(currentPath);
    }, [location]);

    useEffect(() => {
        if (refresh) {
            const currentPath = getCurrentPath();
            fetchFilesAndFolders(currentPath);
        }
    }, [refresh]);

    const selectRange = (currentItem) => {
        const currentIndex = [...folders, ...files].findIndex(item => item.Key === currentItem.Key);
        const lastIndex = [...folders, ...files].findIndex(item => item.Key === lastSelectedItem.Key);
        const range = [...folders, ...files].slice(
            Math.min(currentIndex, lastIndex),
            Math.max(currentIndex, lastIndex) + 1
        );

        const newSelection = [...new Set([...selectedItems, ...range])];
        setSelectedItems(newSelection);
    };

    const selectItem = (item, event) => {
        if (event.metaKey || event.ctrlKey) {
            const isSelected = selectedItems.includes(item);
            if (isSelected) {
                setSelectedItems(selectedItems.filter(i => i !== item));
            } else {
                setSelectedItems([...selectedItems, item]);
            }
            setLastSelectedItem(item);
        } else if (event.shiftKey && lastSelectedItem) {
            selectRange(item);
        } else {
            setSelectedItems([item]);
            setLastSelectedItem(item);
        }
    };

    const handleFolderClick = (folder, event) => {
        selectItem(folder, event);
    };

    const handleFolderDoubleClick = (folder) => {
        const folderPath = folder.Key.replace(/^\//, '').replace(/\/$/, '');
        navigate(`/drive?path=${folderPath}`);
        fetchFilesAndFolders(folderPath);
    };

    const handleFileClick = (file, event) => {
        selectItem(file, event);
    };

    return (
        <div>
            <table className={styles.fileTable}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Last Modified</th>
                </tr>
                </thead>
                <tbody>
                {folders.map((folder) => (
                    <tr
                        key={folder.Key}
                        onClick={(event) => handleFolderClick(folder, event)}
                        onDoubleClick={() => handleFolderDoubleClick(folder)}
                        className={selectedItems.includes(folder) ? styles.selectedItem : ''}
                    >
                        <td className={styles.fileName} title={getBaseName(folder.Key, getCurrentPath())}>
                            {getBaseName(folder.Key, getCurrentPath())}
                        </td>
                        <td>Folder</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                ))}
                {files.map((file) => (
                    <tr
                        key={file.Key}
                        onClick={(event) => handleFileClick(file, event)}
                        className={selectedItems.includes(file) ? styles.selectedItem : ''}
                    >
                        <td className={styles.fileName} title={getBaseName(file.Key, getCurrentPath())}>
                            {getBaseName(file.Key, getCurrentPath())}
                        </td>
                        <td>File</td>
                        <td>{formatFileSize(file.Size)}</td>
                        <td>{new Date(file.LastModified).toLocaleDateString('en-GB')}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default MyFiles;
