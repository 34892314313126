import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import paymentService from '../../API/paymentServices';
import authService from '../../API/authServices';
import invoiceService from '../../API/invoiceServices';
import MainPrompt from '../../Shared/MainPrompt/MainPrompt';
import styles from './Account.module.css';
import Lottie from 'react-lottie';
import fileSenderAnimationJson from '../../Animations/file_sender.json';
import cloudAnimationJson from '../../Animations/cloud.json';
import visa from '../../Assets/visa.png';
import mastercard from '../../Assets/mastercard.png';
import close from '../../Assets/close.svg';
import add from '../../Assets/add.svg';
import tick from '../../Assets/tick.svg';
import unknownCard from '../../Assets/card.svg';
import edit from '../../Assets/edit.svg';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Account() {
    const [accountInfo, setAccountInfo] = useState({
        username: '',
        email: '',
        VAT: '',
        paymentMethods: [],
        plan: null,
        companyName: 'No name available',
        companyAddress: 'No address available',
        hasStripeCustomerId: false
    });
    const [billingHistory, setBillingHistory] = useState([]); // Initialized as an empty array
    const [loading, setLoading] = useState(true);
    const [isPromptOpen, setPromptOpen] = useState(false);
    const [editType, setEditType] = useState(null);
    const [editValue, setEditValue] = useState('');

    const fileSenderAnimation = {
        loop: true,
        autoplay: true,
        animationData: fileSenderAnimationJson,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    };

    const cloudAnimation = {
        loop: true,
        autoplay: true,
        animationData: cloudAnimationJson,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    };

    useEffect(() => {
        fetchAccountInfo();
        fetchBillingHistory();
    }, []);

    const fetchAccountInfo = async () => {
        try {
            const [accountResponse, userResponse] = await Promise.all([
                paymentService.getPaymentAccount().catch(error => {
                    if (error.response && error.response.status === 404) {
                        console.warn("Stripe account not found, displaying limited info.");
                        return { data: { paymentMethods: [] } }; // Empty paymentMethods if not found
                    }
                    throw error;
                }),
                authService.getUser()
            ]);

            const hasStripeCustomerId = accountResponse.data.stripeCustomerId?.trim() !== "";

            setAccountInfo({
                ...accountResponse.data,
                username: userResponse.data.username,
                email: userResponse.data.email,
                VAT: userResponse.data.VAT,
                companyName: userResponse.data.companyName || 'No name available',
                companyAddress: userResponse.data.companyAddress || 'No address available',
                hasStripeCustomerId,
                paymentMethods: accountResponse.data.paymentMethods || [] // Default to empty array
            });
            setLoading(false);
        } catch (error) {
            console.error('Error fetching account information:', error);
            setLoading(false);
        }
    };

    const fetchBillingHistory = async () => {
        try {
            const response = await invoiceService.getUserInvoices();
            const { invoicesB2C = [], invoicesB2B = [] } = response.data || {};
            const combinedInvoices = [
                ...invoicesB2C.map(invoice => ({ ...invoice, type: 'B2C' })),
                ...invoicesB2B.map(invoice => ({ ...invoice, type: 'B2B' }))
            ].sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate));
            setBillingHistory(combinedInvoices);
        } catch (error) {
            console.error('Error fetching billing history:', error);
            setBillingHistory([]); // Default to empty array on error
        }
    };

    const openEditPrompt = (type, currentValue) => {
        setEditType(type);
        setEditValue(currentValue || '');
        setPromptOpen(true);
    };

    const handleEditConfirm = async (value) => {
        try {
            let response;
            switch (editType) {
                case 'username':
                    response = await authService.changeUsername(value);
                    break;
                case 'email':
                    response = await authService.changeEmail(value);
                    break;
                case 'VAT':
                    response = await authService.changeVAT(value);
                    break;
                default:
                    return;
            }
            alert(response.data.message || `${editType} updated successfully.`);
            fetchAccountInfo();
        } catch (error) {
            console.error(`Error updating ${editType}:`, error);
            alert(`Failed to update ${editType}.`);
        } finally {
            setPromptOpen(false);
        }
    };

    const handleDownloadInvoice = async (invoiceNumber) => {
        try {
            const response = await invoiceService.downloadInvoice({ invoiceNumber }, { responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `invoice_${invoiceNumber}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading invoice:', error);
            alert('Failed to download invoice.');
        }
    };



    const handleRenewPlan = () => {
        paymentService.renewPlan()
            .then(response => {
                alert(response.data.message || 'Plan renewed successfully.');
                fetchAccountInfo();
            })
            .catch(error => console.error('Error renewing plan:', error));
    };

    const handleCancelSubscription = () => {
        paymentService.cancelSubscription()
            .then(response => {
                alert(response.data.message || 'Subscription will be canceled at the end of the billing period.');
                fetchAccountInfo();
            })
            .catch(error => console.error('Error canceling subscription:', error));
    };

    const handleSetDefaultCard = (paymentMethodId) => {
        paymentService.setDefaultCard({ paymentMethodId })
            .then(response => {
                alert(response.data.message || 'Payment method set as default successfully.');
                fetchAccountInfo();
            })
            .catch(error => console.error('Error setting default payment method:', error));
    };

    const handleDeleteCard = (paymentMethodId) => {
        paymentService.deleteCard({ paymentMethodId })
            .then(response => {
                alert(response.data.message || 'Payment method removed successfully.');
                fetchAccountInfo();
            })
            .catch(error => console.error('Error deleting payment method:', error));
    };

    const handleAddPaymentMethod = async () => {
        const customerEmail = accountInfo?.email;
        try {
            const response = await paymentService.createPaymentMethodSession(customerEmail);
            const sessionId = response.data.sessionId;
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) alert('Failed to redirect to Stripe checkout.');
        } catch (error) {
            alert('Failed to create payment method session.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (!accountInfo) return <p>Error loading account information.</p>;

    const { username, email, VAT, paymentMethods, plan, companyName, companyAddress } = accountInfo;

    return (
        <div className={styles.account}>
            <Helmet><title>Carica Web - Account</title></Helmet>
            <h2>Welcome Back, {username}</h2>
            <div className={styles.account__section}>
                <h4 className={styles.welcome}>My Apps</h4>
                <div className={styles.account__section__grid}>
                    <Link to="/drive" className={styles.account__section__container}>
                        <div className={styles.lottieWrapper}><Lottie options={cloudAnimation}/></div>
                        <h5 className={styles.app__name}>Carica Cloud Drive</h5>
                    </Link>
                    <Link to="/file-sender" className={styles.account__section__container}>
                        <div className={styles.lottieWrapper}><Lottie options={fileSenderAnimation}/></div>
                        <h5 className={styles.app__name}>File Sender</h5>
                    </Link>
                </div>
            </div>
            <div className={styles.account__section}>
                <h4>My Plan</h4>
                {plan ? (
                    <div className={styles.my__plan__container}>
                        <div>
                            <div className={styles.plan__info}>
                                <h5>{plan.name}</h5>
                                <h5>{plan.price}</h5>
                            </div>
                            <p>{plan.cancelsAtPeriodEnd ? `Your plan will be canceled after ${plan.currentPeriodEnd}.` : `${plan.daysLeft} Days until payment.`}</p>
                        </div>
                        <div className={styles.plan__buttons__wrapper}>
                            {plan.cancelsAtPeriodEnd ? (
                                <button onClick={handleRenewPlan} className='account__button'>Renew Plan</button>
                            ) : (
                                <button onClick={handleCancelSubscription} className='account__button'>Cancel
                                    Plan</button>
                            )}
                            <button className='account__button'>Change Plan</button>
                        </div>
                    </div>
                ) : (
                    <p>No plan selected</p>
                )}
            </div>
            {accountInfo?.hasStripeCustomerId && (
                <div className={styles.account__section}>
                    <h4>Payment Information</h4>
                    <div className={styles.payments__grid}>
                        {paymentMethods.map((method) => (
                            <div
                                className={`${styles.payment} ${method.isDefault ? `${styles.active} ${styles.isActive}` : ''}`}
                                key={method.id}
                                onClick={() => !method.isDefault && handleSetDefaultCard(method.id)}
                            >
                                <h4>Credit / Debit Card</h4>
                                <div className={styles.card__wrapper}>
                                    <img
                                        src={method.brand === 'visa' ? visa : method.brand === 'mastercard' ? mastercard : unknownCard}
                                        className={`${styles.cardImage} ${method.brand === 'visa' ? styles.visa : method.brand === 'mastercard' ? styles.mastercard : styles.unknown}`}
                                        alt={`${method.brand} card`}
                                    />
                                    <p>**** **** **** {method.last4}</p>
                                </div>
                                <button className={styles.close__btn} onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteCard(method.id);
                                }}>
                                    <img src={close} alt="Close"/>
                                </button>
                                {method.isDefault && (
                                    <div className={`${styles.tick__circle}`}><img src={tick} alt="Active"/></div>
                                )}
                            </div>
                        ))}
                        <div className={styles.add__payment}>
                            <button onClick={handleAddPaymentMethod} className={styles.add__button}>
                                <img src={add} alt="Add Payment Method"/>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.account__section}>
                <h4>Personal Information</h4>
                <div className={styles.personal__info}>
                    <div className={styles.personal__info__wrapper}>
                        <h5>Username: {username}</h5>
                        <button className='edit__btn' onClick={() => openEditPrompt('username', username)}>
                            <img src={edit} alt="Edit Username"/>
                        </button>
                    </div>
                    <div className={styles.personal__info__wrapper}>
                        <h5>Email: {email}</h5>
                        <button className='edit__btn' onClick={() => openEditPrompt('email', email)}>
                            <img src={edit} alt="Edit Email"/>
                        </button>
                    </div>
                    <div className={styles.personal__info__wrapper}>
                        <h5>VAT: {VAT}</h5>
                        <button className='edit__btn' onClick={() => openEditPrompt('VAT', VAT)}>
                            <img src={edit} alt="Edit VAT"/>
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.account__section}>
                <h4>Company</h4>
                {(VAT || companyName || companyAddress) ? (
                    <div className={styles.personal__info}>
                        <div className={styles.personal__info__wrapper}><h5>VAT: {VAT}</h5></div>
                        <div className={styles.personal__info__wrapper}>
                            <h5>Name: {companyName || 'No name available'}</h5></div>
                        <div className={styles.personal__info__wrapper}><h5
                            className={styles.address}>Address: {companyAddress || 'No address available'}</h5></div>
                    </div>
                ) : (
                    <div className={styles.add__payment}>
                        <button onClick={() => alert('Add Company Information')} className={styles.add__button}>
                            <img src={add} alt="Add Company Information"/>
                        </button>
                    </div>
                )}
            </div>
            {accountInfo?.hasStripeCustomerId && (
                <div className={styles.account__section}>
                    <h4>Billing History</h4>

                    {/* Desktop Table Layout */}
                    <table className={styles.billingTable}>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Details</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Invoice</th>
                        </tr>
                        </thead>
                        <tbody>
                        {billingHistory.map((invoice, index) => (
                            <tr key={index}>
                                <td>{new Date(invoice.paymentDate).toLocaleDateString()}</td>
                                <td>{invoice.items.map(item => item.name.replace(" + VAT", "")).join(", ")}</td>
                                <td>{invoice.type}</td>
                                <td>€{invoice.items.reduce((total, item) => total + item.price * item.quantity, 0)}</td>
                                <td>
                                    <button className='basic__blue__btn'
                                            onClick={() => handleDownloadInvoice(invoice.invoiceNumber)}>
                                        Download
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* Mobile Card Layout */}
                    <div className={styles.billingHistoryGrid}>
                        {billingHistory.map((invoice, index) => (
                            <div key={index} className={styles.billingCard}>
                                <h5>Date: {new Date(invoice.paymentDate).toLocaleDateString()}</h5>
                                <p>Details: {invoice.items.map(item => item.name.replace(" + VAT", "")).join(", ")}</p>
                                <p>Type: {invoice.type}</p>
                                <p>Amount:
                                    €{invoice.items.reduce((total, item) => total + item.price * item.quantity, 0)}</p>
                                <button className='basic__blue__btn'
                                        onClick={() => handleDownloadInvoice(invoice.invoiceNumber)}>
                                    Download Invoice
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {isPromptOpen && (
                <MainPrompt
                    title={`Edit ${editType}`}
                    description={`Enter new ${editType}`}
                    input={editValue}
                    onConfirm={handleEditConfirm}
                    onCancel={() => setPromptOpen(false)}
                />
            )}
        </div>
    );
}
