import React, { createContext, useState, useEffect } from 'react';
import authService from '../API/authServices';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyUserToken = async () => {
            try {
                const response = await authService.verifyToken();
                if (response.status === 200) {
                    setUser(true); // Set user to a truthy value on successful verification
                    setRole(response.data.role);
                } else {
                    setUser(null);
                }
            } catch (error) {
                console.error('Error verifying token:', error);
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        verifyUserToken();
    }, []);

    const login = async (credentials) => {
        try {
            const response = await authService.login(credentials);
            if (response.status === 200) {
                setUser(true);
                return true;
            }
            return false;
        } catch (error) {
            console.error('Login failed:', error);
            return false;
        }
    };

    const register = async (userData) => {
        try {
            const response = await authService.register(userData);
            if (response.status === 200) {
                setUser(true);
            }
        } catch (error) {
            console.error('Registration failed:', error);
        }
    };

    const logout = async () => {
        try {
            await authService.logout();
            setUser(null);
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, register, logout, loading, role }}>
            {children}
        </AuthContext.Provider>
    );
};
