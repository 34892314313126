import { useEffect, useState, useContext, useRef } from 'react';
import styles from './Checkout.module.css';
import arrow from '../../../Assets/arrow-right.svg';
import paymentService from '../../../API/paymentServices';
import { AuthContext } from '../../../Auth/AuthContext';
import { loadStripe } from "@stripe/stripe-js";
import gsap from "gsap";
import axios from "axios";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Checkout({ selectedPlan, closeCheckout, closing, userIP }) {
    const { user } = useContext(AuthContext);
    const [countryCode, setCountryCode] = useState("");
    const [taxRate, setTaxRate] = useState(0);
    const [vatAmount, setVatAmount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(parseFloat(selectedPlan.price.replace('€', '')));
    const [vatNumber, setVatNumber] = useState('');
    const [companyName, setCompanyName] = useState(null);
    const checkoutRef = useRef(null);

    useEffect(() => {
        const fetchCountryCode = async () => {
            const geo = await axios.get("https://freeipapi.com/api/json");
            if (geo) {
                setCountryCode(geo.data.countryCode);
            } else {
                console.error('Error fetching country code: IP lookup failed');
            }
        };
        fetchCountryCode();
    }, [userIP]);

    useEffect(() => {
        gsap.fromTo(
            checkoutRef.current,
            { x: '100%' },
            { x: 0, duration: 0.5, ease: 'power2.inOut' }
        );
    }, []);

    useEffect(() => {
        if (closing) {
            gsap.to(checkoutRef.current, {
                x: '100%',
                duration: 0.5,
                ease: 'power2.inOut',
                onComplete: closeCheckout
            });
        }
    }, [closing, closeCheckout]);

    const fetchTaxRate = async (vatNumber = null) => {
        try {
            const response = await paymentService.getPriceAfterTax({
                priceId: selectedPlan.priceId,
                countryCode,
                VAT: vatNumber
            });

            const priceWithTax = response.data.priceWithTax ? parseFloat(response.data.priceWithTax) : null;
            const subtotal = parseFloat(selectedPlan.price.replace('€', ''));
            if (priceWithTax !== null) {
                const calculatedVatAmount = priceWithTax - subtotal;
                const calculatedVatAmountFixed = calculatedVatAmount < 0 ? 0 : calculatedVatAmount;
                const calculatedTaxRate = (calculatedVatAmountFixed / subtotal) * 100;
                setVatAmount(calculatedVatAmountFixed);
                setTaxRate(calculatedTaxRate);
                setTotalPrice(calculatedVatAmountFixed === 0 ? subtotal : priceWithTax);
            } else {
                setVatAmount(0);
                setTotalPrice(subtotal);
            }
        } catch (error) {
            console.error('Error fetching tax rate:', error);
        }
    };

    useEffect(() => {
        fetchTaxRate();
    }, [countryCode]);

    const handleVatValidation = async () => {
        try {
            if (!vatNumber) return;
            const response = await paymentService.validateVAT(vatNumber);
            const vatDetails = response.data;
            if (vatDetails.success) {
                setCompanyName(vatDetails.details.name);
                await fetchTaxRate(vatNumber);
            } else {
                setCompanyName(null);
                await fetchTaxRate(null);
            }
        } catch (error) {
            console.error('Error validating VAT:', error);
            setCompanyName(null);
            await fetchTaxRate(null);
        }
    };

    const handlePayment = async () => {
        const stripe = await stripePromise;
        console.log('COUNTRY CODE:', countryCode);
        try {
            const response = await paymentService.createSession(
                [selectedPlan.priceId],
                user?.email || "customer@example.com",
                user?.id || "12345",
                vatNumber,
                countryCode
            );
            const { sessionId } = response.data;
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) console.error('Stripe checkout error:', error);
        } catch (error) {
            console.error('Error creating payment session:', error);
        }
    };

    return (
        <div ref={checkoutRef} className={styles.checkout}>
            <div className={styles.checkout__padding}>
                <h4>Plan Confirmation</h4>
                <div className={styles.checkout__section}>
                    <h5>Plan Details</h5>
                    <p>{selectedPlan.name} Plan</p>
                    {selectedPlan.features.map((feature, index) => (
                        <p key={index}>+ {feature}</p>
                    ))}
                </div>
                <div className={styles.checkout__section}>
                    <h5>Are you purchasing as a company?</h5>
                    <input
                        placeholder='TAX Registration (Optional)'
                        value={vatNumber}
                        onChange={(e) => setVatNumber(e.target.value)}
                    />
                    <button
                        className={`get__started__btn ${styles.apply} ${styles.right}`}
                        onClick={handleVatValidation}
                    >
                        Apply
                    </button>
                    {companyName && <p>Company: {companyName}</p>}
                </div>
                <div className={styles.proceed}>
                    <div className={styles.info}>
                        <p>Subtotal: €{parseFloat(selectedPlan.price.replace('€', '')).toFixed(2)}</p>
                        <p>VAT: €{vatAmount.toFixed(2)}</p>
                        <p>Total: €{totalPrice.toFixed(2)}</p>
                    </div>
                    <div className={styles.button__group}>
                        <button className='get__started__btn' onClick={handlePayment}>
                            Proceed to payment <img src={arrow} alt="arrow"/>
                        </button>
                        <button
                            className='get__started__btn'
                            onClick={() => gsap.to(checkoutRef.current, {
                                x: '100%',
                                duration: 0.5,
                                ease: 'power2.inOut',
                                onComplete: closeCheckout
                            })}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
