import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider, AuthContext } from './Auth/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import { useState, useEffect, useContext } from 'react';
import ProtectedRoute from './Auth/ProtectedRoute';
import Landing from './Features/Landing/Landing';
import Header from './Core/Header/Header';
import Footer from './Core/Footer/Footer';
import Login from './Features/Auth/Login/Login';
import Register from "./Features/Auth/Register/Register";
import Account from "./Features/Account/Account";
import DriveView from "./Features/Drive/DriveView";
import Plans from "./Features/Plans/Plans";
import AdminLayout from "./Features/Admin/AdminLayout";
import Maintenance from "./Core/Maintenance/Maintenance";
import adminServices from './API/adminServices';

function App() {
    const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

    useEffect(() => {
        const checkMaintenanceMode = async () => {
            try {
                const response = await adminServices.getMaintenanceStatus();
                console.log('Maintenance mode:', response);
                const isTrue = response.data.maintenance;
                setIsMaintenanceMode(isTrue === true);
            } catch (error) {
                console.error('Failed to check maintenance mode:', error);
            }
        };

        checkMaintenanceMode();
    }, []);

    return (
        <AuthProvider>
            <HelmetProvider>
                <Router>
                    {isMaintenanceMode ? (
                        <MaintenanceRoutes />
                    ) : (
                        <FullAppRoutes />
                    )}
                </Router>
            </HelmetProvider>
        </AuthProvider>
    );
}

// Conditional routes during maintenance mode
function MaintenanceRoutes() {
    const location = useLocation();
    const { role } = useContext(AuthContext);

    // If the user is an admin, allow access to all routes
    if (role === 'admin') {
        return <FullAppRoutes />;
    }

    // Allow only /admin routes and /login, show Maintenance component elsewhere
    if (location.pathname.startsWith('/admin')) {
        return (
            <Routes>
                <Route element={<ProtectedRoute requiredRole="admin" />}>
                    <Route path="/admin" element={<AdminLayout />} />
                    <Route path="/admin/settings" element={<AdminLayout view="Settings" />} />
                </Route>
            </Routes>
        );
    }

    if (location.pathname === '/login') {
        return (
            <Routes>
                <Route path="/login" element={<Login />} />
            </Routes>
        );
    }

    // Show Maintenance component for all other routes
    return <Maintenance />;
}

// Full application routes when maintenance mode is off
function FullAppRoutes() {
    return (
        <>
            <ConditionalHeader />
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/plans" element={<Plans />} />
                <Route element={<ProtectedRoute />}>
                    <Route path="/account" element={<Account />} />
                    <Route path="/drive" element={<DriveView view="files" />} />
                    <Route path="/drive/shared" element={<DriveView view="shared" />} />
                    <Route path="/drive/deleted" element={<DriveView view="deleted" />} />
                    <Route path="/drive/account" element={<DriveView view="account" />} />
                    <Route path="/drive/plans" element={<DriveView view="plans" />} />
                    <Route path="/drive/dev" element={<DriveView view="dev" />} />
                </Route>
                <Route element={<ProtectedRoute requiredRole="admin" />}>
                    <Route path="/admin" element={<AdminLayout />} />
                    <Route path="/admin/settings" element={<AdminLayout view="Settings" />} />
                </Route>
            </Routes>
            <ConditionalFooter />
        </>
    );
}

function ConditionalHeader() {
    const location = useLocation();
    if (location.pathname.startsWith('/drive') || location.pathname.startsWith('/admin')) {
        return null;
    }
    return <Header />;
}

function ConditionalFooter() {
    const location = useLocation();
    if (location.pathname.startsWith('/drive') || location.pathname.startsWith('/admin')) {
        return null;
    }
    return <Footer />;
}

export default App;