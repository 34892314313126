import axios from 'axios';

// Fetch the client's IP address using a public API
async function fetchIPAddress() {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
    } catch (error) {
        console.error('Failed to fetch IP address:', error);
        return null;
    }
}

// Create an Axios instance
const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,  // API URL from the environment variables
    withCredentials: true,  // Include cookies in requests
    headers: {
        'Content-Type': 'application/json',
    },
});

httpClient.interceptors.request.use(async (config) => {
    const ipAddress = await fetchIPAddress();

    if (ipAddress) {
        config.headers['X-Client-IP'] = ipAddress;
    }

    // Attach the React app token to the headers
    const reactAppToken = process.env.REACT_APP_TOKEN;
    if (reactAppToken) {
        config.headers['X-React-App'] = reactAppToken;  // Add your custom token to the headers
    }

    return config;  // Return the modified config
}, (error) => {
    return Promise.reject(error);  // Handle request errors
});

// Intercept responses to handle errors
httpClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.status === 401) {
        console.log('Unauthorized access detected');
        // Handle 401 Unauthorized errors here
    }
    return Promise.reject(error);
});

export default httpClient;