import styles from './Maintenance.module.css';
import Lottie from 'react-lottie';
import animationData from '../../Animations/Maintenance.json';

export default function Maintenance() {
    return (
        <div className={styles.maintenance}>
            <h1>Under Maintenance</h1>
            <div className={styles.lottie__wrapper}>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                />
            </div>
            <h5>We are testing some exciting new features. We will be back as soon as possible.</h5>
        </div>
    );
}