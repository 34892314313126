import React, { useState } from 'react';
import styles from './DriveOptionsPrompt.module.css';

export default function DriveOptionsPrompt({ title, onCancel, onConfirm }) {
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        setIsClosing(true); // Start the closing animation
        setTimeout(() => {
            onCancel(); // Only trigger the onCancel after the animation has completed
        }, 500); // Extend to 500ms to ensure timing is right
    };

    return (
        <>
            {/* Apply closeAnimation class when isClosing is true */}
            <div className={`${styles.overlay} ${isClosing ? styles.closeAnimation : ''}`} />
            <div className={`${styles.container} ${isClosing ? styles.closeAnimation : ''}`}>
                <h5>{title}</h5>
                <div className={styles.formContainer}>
                    <button
                        onClick={onConfirm}
                        className='prompt__btn green'
                    >
                        Confirm
                    </button>
                    <button
                        onClick={handleClose}  // Close with animation
                        className='prompt__btn red'
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </>
    );
}
