import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import paymentService from '../../../API/paymentServices'; // Correct import
import authService from '../../../API/authServices'; // Correct import
import DriveOptionsPrompt from '../../../Shared/DriveOptionsPrompt/DriveOptionsPrompt'; // Correct import
import styles from './Plans.module.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const planMapping = {
    'price_1QCI6TBas8Ty8TFhI66duE49': { name: '100GB', price: '$2.99', apiCalls: '1500 API requests / month' },
    'price_1QCHs7Bas8Ty8TFhVJoEKd9K': { name: '200GB', price: '$5.50', apiCalls: '3000 API requests / month' },
    'price_1QCHtGBas8Ty8TFhZ1Lij5Ki': { name: '1TB', price: '$25.99', apiCalls: '15,000 API requests / month' },
    'price_1QCHtvBas8Ty8TFhDytwW19G': { name: '2TB', price: '$51.99', apiCalls: '15,000 API requests / month' },
    'price_1QCHuSBas8Ty8TFhNhlMgVlw': { name: '5TB', price: '$129.99', apiCalls: '15,000 API requests / month' },
    'price_1QCHuvBas8Ty8TFh52OaevJ4': { name: '10TB', price: '$259.99', apiCalls: '15,000 API requests / month' },
};

export default function Plans() {
    const stripe = stripePromise;

    const [currentPlan, setCurrentPlan] = useState(''); // Store current plan name here
    const [taxedPrices, setTaxedPrices] = useState({}); // Store prices after tax for plans
    const [showPrompt, setShowPrompt] = useState(false); // To control prompt visibility
    const [selectedPlan, setSelectedPlan] = useState(null); // Track which plan is selected for upgrade or downgrade
    const [isDowngrade, setIsDowngrade] = useState(false); // Track if the action is a downgrade

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await authService.getUser(); // Fetch user data
                const userPlan = response.data.plan; // This should give us the current plan name
                setCurrentPlan(userPlan || 'free'); // Default to 'free' if no plan is found
                console.log("Current Plan:", userPlan); // Log the current plan to ensure it's correct
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };
        fetchUserInfo();
    }, []);

    const getUserCountry = async () => {
        try {
            const response = await fetch('https://geolocation-db.com/json/');
            const data = await response.json();

            const { country_code: countryCode } = data;
            return { countryCode: countryCode || null };
        } catch (error) {
            console.error('Error fetching user country:', error);
            return { countryCode: null };
        }
    };

    // Fetch price after tax for all plans
    useEffect(() => {
        const fetchPricesAfterTax = async () => {
            const customerEmail = "customer@example.com"; // Replace with real customer email
            const userCountry = await getUserCountry(); // Get the user's country

            if (userCountry && userCountry.countryCode) {
                for (const priceId in planMapping) {
                    try {
                        const response = await paymentService.getPriceAfterTax(priceId, customerEmail, userCountry.countryCode);
                        const priceWithTax = response.data.priceWithTax;
                        if (priceWithTax && priceWithTax !== 'Tax might be included') {
                            setTaxedPrices(prev => ({ ...prev, [priceId]: priceWithTax }));
                        }
                    } catch (error) {
                        console.error('Error fetching price after tax:', error);
                    }
                }
            } else {
                console.warn('Could not determine user country.');
            }
        };

        fetchPricesAfterTax();
    }, []);

    const handleCheckout = async (priceId) => {
        const stripeInstance = await stripe;
        try {
            const response = await paymentService.createSession(
                [priceId],
                "customer@example.com", // Replace with real customer email
                "12345"  // Replace with real userId
            );
            const { sessionId } = response.data;
            const { error } = await stripeInstance.redirectToCheckout({ sessionId });
            if (error) console.error('Stripe checkout error:', error);
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    // Function to handle plan change (upgrade or downgrade)
    const handlePlanChange = async (selectedPlan) => {
        try {
            const selectedPlanName = planMapping[selectedPlan].name;

            // If the current plan is "free", directly create a new checkout session
            if (currentPlan === 'free') {
                console.log('User is on the free plan, creating new checkout session...');
                handleCheckout(selectedPlan);
                return;
            }

            // Check if it's a downgrade or upgrade based on the plan order
            const isDowngrade = Object.values(planMapping).findIndex(plan => plan.name === selectedPlanName) <
                Object.values(planMapping).findIndex(plan => plan.name === currentPlan);

            if (isDowngrade) {
                console.log('Starting plan downgrade...');
                await paymentService.downgradePlan({ productId: selectedPlan });
                console.log('Plan downgraded successfully.');
            } else if (!isDowngrade) {
                console.log('Starting plan upgrade...');
                const response = await paymentService.upgradePlan({ productId: selectedPlan }); // Create new session for upgrades
                const { sessionId } = response.data; // Get the session ID from the response
                const stripeInstance = await stripe;
                const { error } = await stripeInstance.redirectToCheckout({ sessionId }); // Redirect to Stripe Checkout
                if (error) console.error('Stripe checkout error:', error);
            } else {
                console.log('Selected plan is the current plan, no change needed.');
            }
        } catch (error) {
            console.error('Error changing plan:', error);
        }
    };

    // Function to handle prompt confirmation for upgrading or downgrading
    const handleConfirmPlanChange = async () => {
        try {
            if (selectedPlan) {
                await handlePlanChange(selectedPlan); // Call plan change (upgrade or downgrade)
            } else {
                console.error('No plan selected.');
            }
        } catch (error) {
            console.error('Error confirming plan change:', error);
        } finally {
            setShowPrompt(false); // Close the prompt
        }
    };

    const renderPlanButton = (planName, priceId) => {
        const isCurrentPlan = currentPlan.trim().toLowerCase() === planName.trim().toLowerCase();
        const isDowngrade = Object.values(planMapping).findIndex(plan => plan.name === planName) <
            Object.values(planMapping).findIndex(plan => plan.name === currentPlan);
        const isUpgrade = !isDowngrade && !isCurrentPlan; // Upgrade is true if it's not a downgrade and not the current plan

        return (
            <button
                className={`plans__btn ${isCurrentPlan ? styles.disabled : ''}`}
                onClick={() => {
                    if (!isCurrentPlan) { // Only allow change if not the current plan
                        if (currentPlan === 'free') {
                            handleCheckout(priceId); // Directly create session if user is on the free plan
                        } else {
                            setSelectedPlan(priceId); // Set selected plan for confirmation
                            setIsDowngrade(isDowngrade); // Set if the plan is a downgrade
                            setShowPrompt(true); // Show confirmation prompt
                        }
                    }
                }}
                disabled={isCurrentPlan} // Disable button for current plan
            >
                {isCurrentPlan ? 'Current Plan' : isDowngrade ? 'Downgrade Plan' : 'Upgrade Plan'}
            </button>
        );
    };

    return (
        <div className={styles.plans}>
            {Object.entries(planMapping).map(([priceId, plan]) => (
                <div key={priceId} className={styles.plan}>
                    <h4>{plan.name}</h4>
                    <div className={styles.planPricing}>
                        <h3>{plan.price} <span className='plan__span'>/ per month</span></h3>
                        <p className={styles.apiCalls}>{plan.apiCalls}</p>
                        {taxedPrices[priceId] && (
                            <p className={styles.taxedPrice}>Price after tax: ${taxedPrices[priceId]}</p>
                        )}
                    </div>
                    {renderPlanButton(plan.name, priceId)}
                </div>
            ))}

            {showPrompt && (
                <DriveOptionsPrompt
                    title={isDowngrade ? "Are you sure you want to downgrade your plan?" : "You are about to upgrade your plan. Unused period of your current plan will be deducted."}
                    onCancel={() => setShowPrompt(false)}
                    onConfirm={handleConfirmPlanChange}
                />
            )}
        </div>
    );
}

