import styles from './Footer.module.css';
import logo from '../../Assets/logo.png'
import {Link} from "react-router-dom";
import instagramIcon from '../../Assets/Instagram.png';

export default function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer__first__wrapper}>
                <div className={styles.logo__wrapper}>
                    <img src={logo}/>
                    <h3>CARICA <span className='main__blue'>WEB</span></h3>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the</p>
            </div>
            <div className={styles.footer__second__wrapper}>
                <ul>
                    <li><Link className='footer__link' to='/'>Home</Link></li>
                    <li><Link className='footer__link' to='/services'>Services</Link></li>
                    <li><Link className='footer__link' to='/plans'>Pricing</Link></li>
                    <li><Link className='footer__link' to='/drive'>Drive</Link></li>
                    <li><Link className='footer__link' to='/file-sender'>File Sender</Link></li>
                    <li><Link className='footer__link' to='/'>Contact us</Link></li>
                </ul>
            </div>
            <div className={styles.footer__third__wrapper}>
                <h4>Social</h4>
                <div className={styles.small__wrapper}>
                    <div>
                        <p>caricapd@gmail.com</p>
                        <p>+359 879385410</p>
                    </div>
                    <div>
                    <a href='https://www.instagram.com/carica_web/' target='_blank'><img src={instagramIcon}/></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}