import React, { useEffect, useState } from 'react';
import styles from './DriveView.module.css';
import { Link } from "react-router-dom";
import HeaderTools from "../../Shared/HeaderTools/HeaderTools";
import MyFiles from "./MyFiles/MyFiles";
import DriveNamePrompt from "../../Shared/DriveNamePrompt/DriveNamePrompt";
import driveService from '../../API/driveServices';
import Plans from "./Plans/Plans";
import DriveAccount from "./Account/DriveAccount";
import {Helmet} from "react-helmet";

export default function DriveView({ view }) {
    const [selectedItems, setSelectedItems] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isPromptVisible, setIsPromptVisible] = useState(false);
    const [storageUsed, setStorageUsed] = useState(0);  // State for used storage in GB or TB
    const [storageAllowed, setStorageAllowed] = useState(100);  // State for allowed storage in GB or TB

    // Convert bytes to GB or TB, depending on the size
    const formatStorage = (bytes) => {
        const GB = 1024 * 1024 * 1024;
        const TB = 1024 * GB;

        if (bytes >= TB) {
            return `${(bytes / TB).toFixed(2)} TB`;
        } else {
            return `${(bytes / GB).toFixed(2)} GB`;
        }
    };

    // Convert Decimal128 to a JavaScript number by extracting the $numberDecimal field
    const convertDecimal128ToNumber = (decimal) => {
        return parseFloat(decimal.$numberDecimal);  // Access the $numberDecimal field and parse it as a float
    };

    useEffect(() => {
        const getStorage = async () => {
            try {
                const storage = await driveService.getStorage();
                console.log(storage);

                // Convert the Decimal128 values to numbers by accessing $numberDecimal
                const usedStorage = convertDecimal128ToNumber(storage.data.storage);
                const allowedStorage = convertDecimal128ToNumber(storage.data.allowed);

                setStorageUsed(formatStorage(usedStorage));  // Format as GB or TB
                setStorageAllowed(formatStorage(allowedStorage));  // Format as GB or TB
            } catch (error) {
                console.error("Error fetching storage data:", error);
            }
        };

        getStorage();
    }, []);

    const getHeading = () => {
        switch (view) {
            case 'files':
                return 'My Files';
            case 'shared':
                return 'Shared Files';
            case 'deleted':
                return 'Deleted Files';
            case 'account':
                return 'Account Information';
            case 'plans':
                return 'Plans';
            case 'dev':
                return 'Developers';
            case 'docs':
                return 'Docs';
            default:
                return 'My Files';
        }
    };

    const uploadFiles = async (selectedFiles) => {
        const folderPath = new URLSearchParams(window.location.search).get('path') || '';

        const formData = new FormData();
        Array.from(selectedFiles).forEach(file => {
            formData.append('files', file);
        });
        formData.append('folderPath', folderPath);

        try {
            await driveService.uploadFiles(formData);
            setRefresh(true);
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    const createFolder = async (folderName) => {
        const folderPath = new URLSearchParams(window.location.search).get('path') || '';
        try {
            await driveService.createFolder(folderName, folderPath);
            setRefresh(true);
        } catch (error) {
            console.error('Error creating folder:', error);
        } finally {
            setIsPromptVisible(false);
        }
    };

    const handleDelete = async () => {
        const folderPath = new URLSearchParams(window.location.search).get('path') || '';

        try {
            for (const item of selectedItems) {
                const itemBaseName = item.Key.split('/').pop();
                const itemFolderPath = item.Key.substring(0, item.Key.lastIndexOf('/'));

                if (item.Type === 'file') {
                    await driveService.deleteFile(itemFolderPath, itemBaseName);
                } else if (item.Type === 'folder') {
                    await driveService.deleteFolder(itemFolderPath);
                }
            }
            setSelectedItems([]);
            setRefresh(true);
        } catch (error) {
            console.error('Error deleting files/folders:', error);
        }
    };

    const handleRefresh = () => {
        setRefresh(true);
    };

    const showCreateFolderPrompt = () => {
        setIsPromptVisible(true);  // Show the prompt when New Folder is clicked
    };

    const handleFolderNameSubmit = (folderName) => {
        createFolder(folderName);  // Call createFolder after receiving folder name from prompt
    };

    // Calculate the percentage of storage used
    const storagePercentage = (storageUsed / storageAllowed) * 100;

    return (
        <div>
            <Helmet>
                <title>Carica Drive</title>
            </Helmet>
            <div className={styles.driveHeader}>
                <h3 className={styles.driveHeading}>CARICA DRIVE</h3>
                <ul>
                    <li className={view === 'files' ? styles.selectedOption : ''}>
                        <Link className="drive__btn" to="/drive">
                            My Files
                        </Link>
                    </li>
                    <li className={view === 'shared' ? styles.selectedOption : ''}>
                        <Link className="drive__btn" to="/drive/shared">
                            Shared Files
                        </Link>
                    </li>
                    <li className={view === 'deleted' ? styles.selectedOption : ''}>
                        <Link className="drive__btn" to="/drive/deleted">
                            Deleted Files
                        </Link>
                    </li>
                    <li className={view === 'account' ? styles.selectedOption : ''}>
                        <Link className="drive__btn" to="/drive/account">
                            Account
                        </Link>
                    </li>
                    <li className={view === 'plans' ? styles.selectedOption : ''}>
                        <Link className="drive__btn" to="/drive/plans">
                            Plans
                        </Link>
                    </li>
                    <li className={view === 'dev' ? styles.selectedOption : ''}>
                        <Link className="drive__btn" to="/drive/dev">
                            Developers
                        </Link>
                    </li>
                    <li className={view === 'docs' ? styles.selectedOption : ''}>
                        <Link className="drive__btn" to="/docs">
                            Docs
                        </Link>
                    </li>
                </ul>

                <div className={styles.availableStorage}>
                    <p>{`${storageUsed} / ${storageAllowed}`}</p>
                    <div className={styles.progressBar}>
                        <div
                            className={styles.progressBarFill}
                            style={{width: `${storagePercentage}%`}}
                        ></div>
                    </div>
                    <button className='white__btn'>Get more storage</button>
                </div>
            </div>

            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <h4>{getHeading()}</h4>
                    <HeaderTools
                        view={view}
                        onUpload={uploadFiles}
                        onCreateFolder={showCreateFolderPrompt}
                        onDelete={handleDelete}
                        onRefresh={handleRefresh}
                    />
                </div>

                {isPromptVisible && (
                    <>
                        <div className={styles.overlay}></div>
                        <DriveNamePrompt
                            prompt="Enter the name of the new folder"
                            placeholder="Folder Name"
                            onSubmit={handleFolderNameSubmit}
                        />
                    </>
                )}

                <div className={styles.contentView}>
                    {view === 'files' && (
                        <MyFiles
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                    )}
                    {view === 'shared' && <div>Displaying Shared Files...</div>}
                    {view === 'deleted' && <div>Displaying Deleted Files...</div>}
                    {view === 'account' && <div><DriveAccount /></div>}
                    {view === 'plans' && <div><Plans allowedStorage={storageAllowed} /></div>}
                    {view === 'dev' && <div>Displaying Developers...</div>}
                </div>
            </div>
        </div>
    );
}