import React, {useEffect, useState} from 'react';
import styles from './Settings.module.css';
import adminServices from "../../../API/adminServices";

export default function Settings() {
    const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

    useEffect(() => {
        getMaintenanceStatus();
    }, []);

    const getMaintenanceStatus = async () => {
        const { data } = await adminServices.getMaintenanceStatus();
        setIsMaintenanceMode(data.maintenance);
    }

    const handleToggle = async () => {
        setIsMaintenanceMode((prev) => !prev);
        await adminServices.toggleMaintenanceStatus()
    };

    return (
        <div className={styles.toggle__container}>
            <label className={styles.toggle__label}>
                <input
                    type="checkbox"
                    checked={isMaintenanceMode}
                    onChange={handleToggle}
                    className={styles.toggle__input}
                />
                <span className={styles.toggle__slider}></span>
            </label>
            <span className={styles.toggle__title}>Maintenance Mode</span>
        </div>
    );
}