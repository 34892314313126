import React, { useState, useContext } from 'react';
import styles from './Login.module.css';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../../Auth/AuthContext';  // Import context

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const { login } = useContext(AuthContext);  // Use login from AuthContext

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const success = await login({ email, password });

        if (success) {
            setEmailError(false);
            setPasswordError(false);
            setError('');

            navigate('/drive');
        } else {
            setError('Login failed. Please check your credentials.');
            setEmailError(true);
            setPasswordError(true);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.infoSection}>
                <div className={styles.bullet}></div>
                <h2>Welcome back! We are happy <br /> to see you <span className='main__blue'> Online</span></h2>
            </div>

            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formSmallHeading}>
                    <h4>Login</h4>
                    <p>Login to access your account</p>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="example@example.com"
                        className={`${styles.input} ${emailError ? styles.errorInput : ''}`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        placeholder="Enter your password"
                        className={`${styles.input} ${passwordError ? styles.errorInput : ''}`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <p className={`${styles.errorMessage} ${error ? styles.showError : ''}`}>
                    {error || ''}
                </p>

                <button type="submit" className='main__btn'>Login</button>

                <div className={styles.action_wrapper}>
                    <p>Don't have an account? <Link className={styles.loginLink} to="/register">Register</Link></p>
                    <p>Forgot your password? <Link className={styles.loginLink} to="/reset-password">Reset it here</Link></p>
                </div>
            </form>
        </div>
    );
}