import styles from './HeaderTools.module.css';
import sun from '../../Assets/sun.svg';
import trash from '../../Assets/trash.svg';
import share from '../../Assets/share.svg';
import refresh from '../../Assets/refresh.svg';
import upload from '../../Assets/upload.svg';
import newFolder from '../../Assets/newFolder.svg';
import download from '../../Assets/download.svg'

export default function HeaderTools({ view, onUpload, onCreateFolder, onDelete, onRefresh }) {
    const handleFileChange = (event) => {
        const files = event.target.files;
        onUpload(files);
    };

    const triggerFileUpload = () => {
        document.getElementById('fileUploadInput').click();
    };

    return (
        <div>
            {view === 'files' && (
                <div className={styles.headerToolsWrapper}>
                    <button className={styles.headerToolsBtn}><img src={download} alt="Download"/></button>
                    <input
                        id="fileUploadInput"
                        type="file"
                        multiple
                        style={{display: 'none'}}
                        onChange={handleFileChange}
                    />
                    <button className={`${styles.headerToolsBtn} ${styles.uploadBtn}`} onClick={triggerFileUpload}>
                        <img src={upload} alt="Upload"/>
                    </button>
                    <button className={styles.headerToolsBtn} onClick={onCreateFolder}>
                        <img src={newFolder} alt="New Folder"/>
                    </button>
                    <button className={styles.headerToolsBtn} onClick={onDelete}><img src={trash} alt="Delete"/>
                    </button>
                    <button className={styles.headerToolsBtn} onClick={onRefresh}><img src={refresh} alt="Refresh"/>
                    </button>
                    <button className={styles.headerToolsBtn}><img src={share} alt="Share"/></button>
                    <button className={styles.headerToolsBtn}><img src={sun} alt="Change Theme"/></button>
                </div>
            )}
        </div>
    );
}
