import httpClient from './httpClient';

const driveService = {
    getFiles: (folderPath) => {
        return httpClient.post('/drive/read-folder', { folderPath });
    },
    uploadFiles: (formData) => {
        return httpClient.post('/drive/upload-files', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
    deleteFile: (folderPath, fileName) => {
        return httpClient.delete('/drive/delete-file', {
            data: { folderPath, fileName }
        });
    },
    deleteFolder: (folderPath) => {
        return httpClient.delete('/drive/delete-folder', {
            data: { folderPath }
        });
    },
    createFolder: (folderName, folderPath) => {
        return httpClient.post('/drive/create-folder', { folderName, folderPath });
    },
    getStorage: () => {
        return httpClient.get('/drive/storage');
    }
};

export default driveService;
