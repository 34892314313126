import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js'; // Import loadStripe for Stripe integration
import paymentService from '../../../API/paymentServices';
import styles from './DriveAccount.module.css';
import visa from '../../../Assets/visa.png';
import mastercard from '../../../Assets/mastercard.png';
import close from '../../../Assets/close.svg';
import add from '../../../Assets/add.svg';
import tick from '../../../Assets/tick.svg';
import unknownCard from '../../../Assets/card.svg';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function DriveAccount() {
    const [accountInfo, setAccountInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch the account information when the component mounts
        paymentService.getPaymentAccount()
            .then(response => {
                setAccountInfo(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching account information:', error);
                setLoading(false);
            });
    }, []);

    const handleCancelSubscription = () => {
        paymentService.cancelSubscription()
            .then(response => {
                alert(response.data.message || 'Subscription will be canceled at the end of the billing period.');
                // Optionally refresh account info after cancellation
                paymentService.getPaymentAccount().then(res => setAccountInfo(res.data));
            })
            .catch(error => {
                console.error('Error canceling subscription:', error);
                alert('Failed to cancel subscription.');
            });
    };

    const handleRenewPlan = () => {
        paymentService.renewPlan()
            .then(response => {
                alert(response.data.message || 'Plan renewed successfully.');
                // Optionally refresh account info after renewal
                paymentService.getPaymentAccount().then(res => setAccountInfo(res.data));
            })
            .catch(error => {
                console.error('Error renewing plan:', error);
                alert('Failed to renew plan.');
            });
    };

    const handleAddPaymentMethod = async () => {
        const customerEmail = accountInfo.email;

        try {
            // Fetch the Stripe session ID for payment setup
            const response = await paymentService.createPaymentMethodSession(customerEmail);
            const sessionId = response.data.sessionId;

            // Redirect to Stripe setup using the session ID
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId });

            if (error) {
                console.error('Error redirecting to Stripe:', error);
                alert('Failed to redirect to Stripe checkout.');
            }
        } catch (error) {
            console.error('Error creating payment method session:', error);
            alert('Failed to create payment method session.');
        }
    };

    const handleDeleteCard = (paymentMethodId) => {
        paymentService.deleteCard({ paymentMethodId })
            .then(response => {
                alert(response.data.message || 'Payment method removed successfully.');
                // Refresh account info to update the UI after deletion
                paymentService.getPaymentAccount().then(res => setAccountInfo(res.data));
            })
            .catch(error => {
                console.error('Error deleting payment method:', error);
                alert('Failed to remove payment method.');
            });
    };

    const handleSetDefaultCard = (paymentMethodId) => {
        paymentService.setDefaultCard({ paymentMethodId })
            .then(response => {
                alert(response.data.message || 'Payment method set as default successfully.');
                // Refresh account info to reflect the change
                paymentService.getPaymentAccount().then(res => setAccountInfo(res.data));
            })
            .catch(error => {
                console.error('Error setting default payment method:', error);
                alert('Failed to set payment method as default.');
            });
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!accountInfo) {
        return <p>Error loading account information.</p>;
    }

    const { plan, paymentMethods, billingHistory } = accountInfo;

    return (
        <div>
            <h4 className={styles.plansHeading}>My Plan</h4>
            <div className={styles.my__plan__container}>
                <div className={styles.plan__info__wrapper}>
                    <div className={styles.plan__text__wrapper}>
                        <h4>{plan.name}</h4>
                        <h4>{plan.price}</h4>
                    </div>

                    {/* Show either the payment or the cancellation date */}
                    {plan.cancelsAtPeriodEnd ? (
                        <p>Your plan will be canceled after {plan.currentPeriodEnd}.</p>
                    ) : (
                        <p>{plan.daysLeft} Days until payment.</p>
                    )}
                </div>

                <div className={styles.my__plan__btns__wrapper}>
                    {plan.cancelsAtPeriodEnd ? (
                        <button onClick={handleRenewPlan} className='plans__btn'>
                            Renew Plan
                        </button>
                    ) : (
                        <button onClick={handleCancelSubscription} className='plans__btn'>
                            Cancel Subscription
                        </button>
                    )}
                    <button className='plans__btn'>Upgrade</button>
                </div>
            </div>

            <h4 className={styles.plansHeading}>Payment Method</h4>
            <div className={styles.payments__grid}>
                {paymentMethods.map((method) => (
                    <div
                        className={`${styles.payment} ${method.isDefault ? styles.active : ''}`}
                        key={method.id}
                        onClick={() => {
                            if (!method.isDefault) {
                                handleSetDefaultCard(method.id);
                            }
                        }}
                    >
                        <h4>Credit / Debit Card</h4>
                        <div className={styles.card__wrapper}>
                            <img
                                src={method.brand === 'visa' ? visa : method.brand === 'mastercard' ? mastercard : unknownCard}
                                className={method.brand === 'visa' ? styles.visa : method.brand === 'mastercard' ? styles.mastercard : styles.unknown}
                                alt={`${method.brand} card`}
                            />
                            <p>**** **** **** {method.last4}</p>
                        </div>
                        <button
                            className={styles.close__btn}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteCard(method.id);
                            }}
                        >
                            <img src={close} alt="Close"/>
                        </button>
                        {method.isDefault && (
                            <div className={styles.tick__circle}>
                                <img src={tick} alt="Active"/>
                            </div>
                        )}
                    </div>
                ))}

                {/* Add Payment Method Button */}
                <div className={styles.add__payment}>
                    <button onClick={handleAddPaymentMethod} className={styles.add__button}>
                        <img src={add} alt="Add Payment Method"/>
                    </button>
                </div>
            </div>

            <h4 className={styles.billingHeading}>Billing History</h4>
            <table className={styles.billingTable}>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Details</th>
                    <th>Amount</th>
                    <th>Invoice</th>
                </tr>
                </thead>
                <tbody>
                {billingHistory.map((invoice, index) => (
                    <tr key={index}>
                        <td>{invoice.date}</td>
                        <td>{invoice.details}</td>
                        <td>€{invoice.amount}</td>
                        <td>
                            <a href={invoice.invoiceUrl} target="_blank" rel="noopener noreferrer">
                                Download from Stripe
                            </a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}