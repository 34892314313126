import React, { useState } from 'react';
import styles from './DriveNamePrompt.module.css';

export default function DriveNamePrompt({ prompt, placeholder, onSubmit }) {
    const [folderName, setFolderName] = useState('');

    const handleInputChange = (event) => {
        setFolderName(event.target.value);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        onSubmit(folderName);
    };

    return (
        <>
            <div className={styles.overlay}></div>
            <div className={styles.container}>
                <h4>{prompt}</h4>
                <form onSubmit={handleFormSubmit}>
                    <input
                        placeholder={placeholder}
                        name='name'
                        value={folderName}
                        onChange={handleInputChange}
                        required
                    />
                    <button type='submit' className='white__btn'>Create</button>
                </form>
            </div>
        </>
    );
}
