import React, { useState } from 'react';
import styles from './MainOptionsPrompt.module.css'
import {Link} from "react-router-dom";

export default function MainOptionsPrompt({ title, description, onCancel, onConfirm }) {
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onCancel();
        }, 500);
    };

    return (
        <>
            <div className={`${styles.overlay} ${isClosing ? styles.closeAnimation : ''}`} />
            <div className={`${styles.container} ${isClosing ? styles.closeAnimation : ''}`}>
                <h5>{title}</h5>
                <h6>{description}</h6>
                <p>By proceeding you agree to our <Link to='/terms'>Terms of Usage</Link></p>
                <div className={styles.formContainer}>
                    <button
                        onClick={onConfirm}
                        className='prompt__btn green'
                    >
                        Confirm
                    </button>
                    <button
                        onClick={handleClose}
                        className='prompt__btn red'
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </>
    );
}
