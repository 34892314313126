import { useState } from 'react';

export default function MainBtn({ text, onClick, type = "button" }) {
    const [loading, setLoading] = useState(false);

    const handleClick = async (e) => {
        e.preventDefault();
        if (onClick) {
            setLoading(true);
            try {
                await onClick(e);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <button
            className={`main__btn ${loading ? 'loading' : ''}`}
            onClick={handleClick}
            type={type}
            disabled={loading}
        >
            {loading ? <div className="loader"></div> : text}
        </button>
    );
}
