import React, { useState } from 'react';
import styles from './MainPrompt.module.css';
import { Link } from 'react-router-dom';

export default function MainPrompt({ title, description, input, onCancel, onConfirm }) {
    const [inputValue, setInputValue] = useState(input);

    const handleInputChange = (e) => setInputValue(e.target.value);

    return (
        <div className={styles.overlay}>
            <div className={styles.container}>
                <h5>{title}</h5>
                <h6>{description}</h6>
                <input
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={description}
                />
                <p>
                    By proceeding you agree to our
                    <Link to='/terms'>Terms of Usage</Link>
                </p>
                <div className={styles.formContainer}>
                    <button
                        onClick={() => onConfirm(inputValue)}
                        className="prompt__btn green"
                    >
                        Confirm
                    </button>
                    <button onClick={onCancel} className="prompt__btn red">Cancel</button>
                </div>
            </div>
        </div>
    );
}
